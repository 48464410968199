/**
 * Newsroom post feed
 * DBS>Interactive
 */
 .partial-blog-feed {
	@include vertical-padding;
	position: relative;

	@include media($screen-sm-max, 'max') {
		background: $grayLightest;
	}

	.blog-sidebar {display: none;}
 }

 .latest-news {

	.h4 {
		position: relative;

		&:after {
			border-top: 2px solid #00a78d;
			content: '';
			position: absolute;
			top: .8rem;
			right: 0;
			width: calc(100% - 10rem);

			@include media($screen-sm) {display: none;}

			@include media($screen-lg) {
				display: block;
				left: 11rem;
				right: auto;
				width: 7.5rem;
			}
		}
	}

	.post {
		background: $white;
		padding: $spacing;
		outline: 1px solid $grayLighter;
		transition: .25s ease-in-out;

		&__meta {margin-bottom: $spacing;}

		&__title a {font-weight: 700;}

		&__image {display: none;}

		&:hover {
			background: $mint;

			.arrow-link.boxed:after {
				background-color: $green;
				background-image: url('../icons/src/arrow-right-white-2.svg');
				transform: translate(.25rem, -50%);
			}
		}
	}

	.blog-main {margin-bottom: $spacing;}

	@include media($screen-sm) {
		.post {
			display: flex;
				flex-direction: column;
			width: 50%;

			&__link {margin-top: auto;}
		}

		.blog-main {
			display: flex;
				flex-wrap: wrap;
		}
	}

	@include media($screen-md) {
		.post {width: 25%;}
	}
}
