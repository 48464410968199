/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
	height: 2rem;
	position: relative;

	@include media($screen-sm-max, 'max') {
		display: block;
		margin-top: $spacing * 2;
	}

	@include media($screen-sm) {
		float: right;
	}

	svg {
		// Necessary for ie height fix
		height: 2rem;
		max-width: 1rem;
	}

	strong {
		@include font-scale(-1, $bodyFontSize);
		float:right;
		font-weight: normal;
		text-align: center;
		width: 17em;

		@include media($screen-sm) {width: 17em;}
	}

	.text {
		max-width: 0;
		overflow: hidden;
		padding: .25em 0;
		position: absolute;
			right: 2em;
			top: 1px;
		transition: all .5s ease-out;
	}

	&.hover span, &:hover span, &:focus span { max-width: 14em; }
}
