@mixin facility-active {
	opacity: 1;
	transform: translate3d(calc(-100% - 1.5rem), -50%, 0);
	transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s 0s linear;
	visibility: visible;
	z-index: 20;
}

@mixin dot-active {
	z-index: 10;

	&:before {
		height: 3.75rem !important;
		width: 3.75rem !important;
	}
	&:after {
		background: $greenLighter !important;
		border-color: $white !important;
		border-width: 1px !important;
		height: 2.5rem !important;
		width: 2.5rem !important;
	}
}


.facilities-map {
	background-color: $mint;
	position: relative;
	width: 100%;

	.kao-map {
		padding: $spacing 0;
		width: 100%;
	}

	.map-dot {
		@include size(.5rem);
		background: 0;
		border: none;
		border-radius: 50%;
		padding: 0;
		position: absolute;
		transition: .25s ease-in-out;
		transform-origin: center;
		z-index: 1;

		&:before,
		&:after {
			@include size(.5rem);
			border-radius: 50%;
			content: '';
			position: absolute;
				top: 50%;
				left: 50%;
			transform: translate(-50%,-50%);
			transition: .25s ease-in-out;
		}

		&:before {
			background: $green;
			z-index: 2;
		}

		&:after {
			@include size(2.5rem);
			background: rgba($greenLighter, 0);
			border: 1px solid rgba($white, 0);
			z-index: 3;
		}

		&:hover,
		&:focus,
		&.active {
			@include dot-active;

			&.africa {& ~ .facility-info.africa {@include facility-active;}}
			&.americas {& ~ .facility-info.americas {@include facility-active;}}
			// Come from right to avoid overlap with dots
			&.asia {& ~ .facility-info.asia {
					@include facility-active;
					transform: translate3d(100%,-50%,0);
				}
			}
			&.europe {& ~ .facility-info.europe {@include facility-active;}}
		}

		&.medium {
			@include size(.75rem);

			&:after { // Relative units at this size were having pixel rounding issues
				@include size(16px);
				border: .25rem solid $white;
			}

			&:before {@include size(18px);}
		}

		&.large {
			@include size(1rem);

			&:after {
				@include size(22px);
				border: .25rem solid $white;
			}

			&:before {@include size(24px);}
		}


		&:not(.large):hover,
		&:not(.large):focus {
			&.americas {
				~ .map-dot.large.americas {@include dot-active;}
			}
			&.asia {
				~ .map-dot.large.asia {@include dot-active;}
			}
			&.africa {
				~ .map-dot.large.africa {@include dot-active;}
			}
			&.europe {
				~ .map-dot.large.europe {@include dot-active;}
			}
		}
	}

	.facility-info {
		background-color: rgba($green, .95);
		display: flex;
			flex-direction: column;
			justify-content: center;
		min-height: 100%;
		opacity: 0;
		padding: $spacing;
		position: absolute;
			top: 50%;
			left: calc(100% + 3rem);
		transform: translate3d(0,-50%,0);
		transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s .25s linear;
		visibility: hidden;
		width: 20rem;

		&.active {@include facility-active;}

		&.asia {
			left: auto;
			right: calc(100% + 1.5rem);

			&.active {transform: translate3d(100%,-50%,0);}
		}

		&__toggle {
			background: url('../icons/src/close-symbol-white.svg') no-repeat center;
				background-size: 2.5rem;
			height: 5rem;
			position: absolute;
				top: 0;
				right: 0;
			width: 5rem;
		}

		@include media($screen-sm-max, 'max') {
			border: 1px solid $white;
		}
	}
}
