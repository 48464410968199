/**
 * Slate Layout Mixins
 * DBS>Interactive
 */


/**
 * Default responsive padding mixin for layout content
 */
@mixin default-padding {
	padding-left: $spacing;
	padding-right: $spacing;
}

@mixin vertical-padding {
	padding-bottom: ($vSpacing * 2);
	padding-top: ($vSpacing * 2);

	@include media ($screen-sm) {
		padding-bottom: ($vSpacing * 3);
		padding-top: ($vSpacing * 3);
	}
}


/**
 * Default responsive margin mixin for layout content
 */
@mixin default-margin {
	margin: ($vSpacing * 2) auto;

	@include media ($screen-sm) {
		margin: ($vSpacing * 3) auto;
	}
}


/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
@mixin cell-space-between($space_property: margin, $reverse: false) {
	@if $reverse {
		#{$space_property}: ($spacing * 2) $spacing ($spacing * 2) 0;
		&:last-of-type { #{$space_property}-left: 0; }
	} @else {
		#{$space_property}: ($spacing * 2) 0 ($spacing * 2) $spacing;
		&:last-of-type { #{$space_property}-right: 0; }
	}
}


/**
 * Responsive layout mixin to constrain a width to containSize.
 */
@mixin constrained-width($width: $containSize) {
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
}
