ul.mega-menu {
	

	@include media($mobileNavBPMax, 'max') {

		.mega-menu__column {
			border-bottom: 1px solid $grayLighter;
			position: relative;

			.h5 {
				font-family: $font-montserrat;
				font-weight: 700;
				font-size: 1rem;
				padding: $spacing $spacing $spacing $spacing*2;
				text-transform: none;
				margin: 0;
			}

			ul {
				max-height: 0;
				visibility: hidden;
			}

			.submenu-toggle.open + ul {
				max-height: none;
				visibility: visible;
			}
		}

		.main-menu__item {
			border-top: 1px solid #cbd0d6;

			a {padding-left: $spacing*3;}
		}
	}

	@include media($mobileNavBP) {
		display: flex;
		padding: $spacing !important;

		.h5 {
			border-bottom: 2px solid $greenLightest;
			margin-bottom: 1rem;
			padding-bottom: .25rem;
		}

		.mega-menu__column:not(:last-child) {padding-right: $spacing;}

		.main-menu__item {
			min-width: 17rem;

			a {
				padding-left: 0;
				padding-right: 0;

				&:after {
					content: '';
					display: block;
					border-bottom: 2px solid $greenLightest;
					transform: scaleX(0) translate(0, 2px);
					transform-origin: 100% 0;
					transition: transform .3s ease-in-out;
				}
	
				&:hover,
				&:focus {
					background: $white;

					&:after {
						transform: scaleX(1) translate(0, 2px);
						transform-origin: 0 100%;
					}
				}
			}
		}

		.submenu-toggle {display: none;}
	}

	@include media($screen-md) {
		.mega-menu__column:not(:last-child) {padding-right: $spacing*2;}
	}
}