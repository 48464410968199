.partial-subscribe.layout {
	border-top: 1px solid $grayLighter;
	margin: 0 auto;
}

.subscribe {
	padding: #{$spacing *2} 0;

	@include media($screen-sm) {
		display: flex;
			align-items: center;
			justify-content: space-evenly;

		.h4 {
			margin-bottom: 0;
			padding-right: $spacing*3;
		}
	}
}

// When following an innovations slider
.innovations-slider + .partial-subscribe {
	margin-top: -1px !important;
	position: relative;
	z-index: 2;
}