/**
 * Global Slider Styles
 * DBS>Interactive
 */

.slider-cell {
	min-height: 100%;
	width: 100%; // Set number of visible slides by width percentage. This is one slide.
}

.flickity-viewport:focus,
.flickity-enabled:focus {box-shadow: none;}

.slider-cell {
	&__wrapper {
		width: 100%;
	}

	&__image {
		@include size(14rem);
		background: $white;
		border: 2px solid $white;
		border-radius: 50%;
		position: relative;

		img {
			@include object-fit(cover, center);
			border-radius: 50%;
		}
	}
}

.flickity-button {
	background-color: $white;
	background-image: url('../icons/src/arrow-right-green.svg') !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
	background-size: 1rem !important;
	border: 1px solid $green;
	padding: 0;
	transform-origin: 50% 25%;

	&.previous {
		left: .75rem;
		transform: rotate(180deg);
	}

	&.next {right: .75rem;}

	&-icon {display: none;}
}

.flickity-page-dots {
	display: flex;
        align-items: center;
        justify-content: center;
    position: relative;

	.dot {
		@include size(1rem);
		background: white;
		border: 1px solid $green;
		opacity: 1;

		&.is-selected {background: $green;}
	}
}

.flickity-page-dots {
	margin-top: $spacing;
	padding: $spacing;
	position: relative;
	bottom: 0;

	&:before {
		background: $grayLightest;
		content: '';
		height: 100%;
		position: absolute;
			left: 3rem;
		width: 100%;
		z-index: -1;
	}
}

.flickity-viewport {overflow: visible;}


@include media($screen-lg) {
	.flickity-button {
		@include size(4rem);
		background-size: 1.25rem !important;
		border-width: 2px;
	}
}