.full-cta {
	@include vertical-padding;
	position: relative;

	&__container {
		border: 1px solid $white;
		max-width: calc(#{$containSize} - #{$spacing * 2});
	}

	&__text {
		padding: #{$spacing * 2} $spacing;
	}

	@include media($screen-sm-max, 'max') {
		&:after {content: none;}

		h3 {font-size: 1.5rem;}
	}

	@include media($screen-sm) {

		&__text {
			display: flex;
				align-items: center;
				justify-content: space-between;
			padding: #{$spacing * 2} $spacing;

			&-left {
				border-left: 2px solid $white;
				max-width: 48rem;
				padding: 0 #{$spacing * 2};
			}

			h3 {margin-bottom: 0;}
		}

		.button.arrow {
			margin: 0 auto;
		}
	}

	@include media($screen-md) {
		&__text {
			padding-left: $spacing*2;

			&-left {
				padding-right: $spacing * 3;
			}
		}
	}
}