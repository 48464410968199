/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Minimal-Glyph Fonts For English Site
 */
@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Black.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Bold.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraBold.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Light.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Medium.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraLight.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Regular.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Thin.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Oswald';
  src: url("/wp-content/themes/kao-print/library/fonts/oswald/subset-Oswald-Bold.woff2") format("woff2"), url("/wp-content/themes/kao-print/library/fonts/oswald/subset-Oswald-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

/**
 * Primary Font Family
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Type style definitions
 */
.layout-sustainability {
  border-bottom: 1px solid #F2F5FA;
  border-top: 1px solid #F2F5FA;
  overflow: hidden; }
  .layout-sustainability .eyebrow {
    color: #007966; }
  .layout-sustainability__container {
    padding-bottom: 3rem;
    padding-top: 3rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    position: relative; }
    @media screen and (min-width: 48rem) {
      .layout-sustainability__container {
        padding-bottom: 4.5rem;
        padding-top: 4.5rem; } }
  .layout-sustainability__left {
    text-align: center; }
  .layout-sustainability__right {
    margin-bottom: 4.5rem;
    text-align: center;
    position: relative; }
    .layout-sustainability__right h2 {
      margin-bottom: 3rem; }
  .layout-sustainability__img {
    margin: 0 auto;
    width: calc(100% - 6rem); }
  .layout-sustainability__accent {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 7rem; }
  @media screen and (max-width: 47.999rem) {
    .layout-sustainability__img::after {
      background: #F6FCFC;
      content: '';
      height: 150%;
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      z-index: -1; } }
  @media screen and (min-width: 48rem) {
    .layout-sustainability {
      overflow-y: hidden; }
      .layout-sustainability__container {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-direction: row;
        flex-direction: row; }
      .layout-sustainability__left {
        text-align: left;
        width: 55%; }
      .layout-sustainability__right {
        padding-left: 4rem;
        text-align: right;
        width: 45%; }
      .layout-sustainability__accent {
        bottom: -3rem;
        left: 1.5rem; }
      .layout-sustainability .header-lines__line:last-child {
        visibility: hidden; } }
  @media screen and (min-width: 62rem) {
    .layout-sustainability__container:before {
      background: repeating-linear-gradient(#5EC3B3, #5EC3B3 1px, white 0px 3px, white 3px);
      content: '';
      height: 30%;
      position: absolute;
      left: calc(100% - 1.5rem);
      bottom: 0;
      width: 50vw;
      z-index: 0; }
    .layout-sustainability__right:after {
      background: #F6FCFC;
      content: '';
      height: calc(100% + 13.5rem);
      position: absolute;
      top: -4.5rem;
      left: calc(50% + 1.5rem);
      width: 50vw;
      z-index: -1; }
    .layout-sustainability__img {
      width: calc(100% - 4rem); }
    .layout-sustainability__accent {
      bottom: 0;
      left: 0;
      width: 10rem; } }

.innovations-slider + .layout-sustainability {
  margin-top: -1px !important; }

/**
 * Extended attribute classes for Flex Layouts
 * DBS>Interactive
 */
.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative; }
  .bg-img > .image-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1; }
    .bg-img > .image-wrapper img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .bg-img > .image-wrapper img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .bg-img > .contain {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .bg-img h1, .bg-img h2, .bg-img h3, .bg-img h4, .bg-img h5, .bg-img h6, .bg-img p {
    color: #ffffff; }

.bg-dark, .bg-green {
  background-color: #16181e; }
  .bg-dark, .bg-green, .bg-dark h1, .bg-green h1, .bg-dark h2, .bg-green h2, .bg-dark h3, .bg-green h3, .bg-dark h4, .bg-green h4, .bg-dark h5, .bg-green h5, .bg-dark h6, .bg-green h6 {
    color: #ffffff; }

.bg-green {
  background-color: #007966; }

.bg-light {
  background-color: #818f9c; }

.bg-overlay .cell, .bg-overlay .contain {
  position: relative;
  z-index: 1; }

.bg-overlay:after {
  background: rgba(18, 18, 18, 0.7);
  content: '';
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%; }

.no-bottom-margin {
  margin-bottom: 0 !important; }

.no-top-margin {
  margin-top: 0 !important; }

.thumbnail .column {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1em; }
  @media screen and (min-width: 48rem) {
    .thumbnail .column {
      padding: 0 1.5rem 1.5rem; } }
  .thumbnail .column > :first-child {
    margin-right: 1.5rem; }

.thumbnail .cell {
  -ms-flex: 1;
  flex: 1;
  padding: 0; }

.thumbnail .image-wrapper {
  height: 0;
  padding-top: 5em;
  overflow: hidden;
  position: relative;
  width: 6em; }
  .thumbnail .image-wrapper img {
    height: 100%;
    max-width: initial;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; }

.image-card .image-wrapper {
  margin: 0 1.5rem 1.5rem 1.5rem;
  margin-right: 1.5rem;
  max-height: 20em;
  overflow: hidden; }
  .image-card .image-wrapper img {
    height: 100%; }

.color-panel {
  position: relative; }
  .color-panel:before, .color-panel:after {
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1; }
  .color-panel:before {
    left: 0;
    width: 50%; }
  .color-panel:after {
    right: 100%;
    width: 100vw; }
  @media screen and (min-width: 48rem) {
    .color-panel:before {
      width: 40%; } }
  @media screen and (min-width: 62rem) {
    .color-panel:before {
      width: 25%; } }
  .color-panel.light-green-panel:before, .color-panel.light-green-panel:after {
    background: #5EC3B3; }
  .color-panel.green-panel:before, .color-panel.green-panel:after {
    background: #00A78d; }
  .color-panel.gray-panel:before, .color-panel.gray-panel:after {
    background: #F2F5FA; }

.circle-bg {
  position: relative; }
  .circle-bg:before {
    animation: circleRotate 1s ease-in-out 1s forwards;
    background: url("/wp-content/themes/kao-print/library/images/circle-arches.svg") no-repeat center;
    background-size: contain;
    content: '';
    height: 115%;
    width: 115%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(-145deg); }

@keyframes circleRotate {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(-145deg); }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(-5deg); } }

.full-cta {
  padding-bottom: 3rem;
  padding-top: 3rem;
  position: relative; }
  @media screen and (min-width: 48rem) {
    .full-cta {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .full-cta__container {
    border: 1px solid #ffffff;
    max-width: calc(80rem - 3rem); }
  .full-cta__text {
    padding: 3rem 1.5rem; }
  @media screen and (max-width: 47.999rem) {
    .full-cta:after {
      content: none; }
    .full-cta h3 {
      font-size: 1.5rem; } }
  @media screen and (min-width: 48rem) {
    .full-cta__text {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 3rem 1.5rem; }
      .full-cta__text-left {
        border-left: 2px solid #ffffff;
        max-width: 48rem;
        padding: 0 3rem; }
      .full-cta__text h3 {
        margin-bottom: 0; }
    .full-cta .button.arrow {
      margin: 0 auto; } }
  @media screen and (min-width: 62rem) {
    .full-cta__text {
      padding-left: 3rem; }
      .full-cta__text-left {
        padding-right: 4.5rem; } }

.innovations-slider {
  border-top: 1px solid #F2F5FA;
  border-bottom: 1px solid #F2F5FA;
  text-align: center; }
  .innovations-slider .slider {
    padding: 0 1.5rem; }
    .innovations-slider .slider-cell {
      opacity: 0;
      transition: .25s ease-in-out; }
    .innovations-slider .slider-cell__wrapper.is-selected .slider-cell {
      opacity: 1; }
  .innovations-slider .slider__links {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
    .innovations-slider .slider__links .button.ghost {
      margin-bottom: 1.5rem; }
  @media screen and (max-width: 47.999rem) {
    .innovations-slider {
      padding-left: 0 !important;
      padding-right: 0 !important; }
      .innovations-slider:before {
        content: none; }
      .innovations-slider .flickity-button {
        top: 7rem; }
      .innovations-slider .flickity-viewport {
        overflow: hidden; }
      .innovations-slider__header {
        padding-bottom: 3rem;
        padding-top: 3rem;
        background-color: #00A78d;
        padding-bottom: 12rem; } }
    @media screen and (max-width: 47.999rem) and (min-width: 48rem) {
      .innovations-slider__header {
        padding-bottom: 4.5rem;
        padding-top: 4.5rem; } }
  @media screen and (max-width: 47.999rem) {
        .innovations-slider__header .h3 {
          margin-bottom: 0; }
      .innovations-slider .slider {
        margin-top: -7rem; }
        .innovations-slider .slider-cell__image {
          margin: 0 auto 4.5rem; } }
  @media screen and (min-width: 48rem) {
    .innovations-slider {
      padding-bottom: 6rem;
      position: relative;
      text-align: left; }
      .innovations-slider__header {
        padding-bottom: 1.5rem;
        padding-left: 0;
        padding-top: 4.5rem; }
      .innovations-slider .slider-cell {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-left: auto; }
        .innovations-slider .slider-cell__image {
          height: 16rem;
          width: 16rem;
          margin-right: 4.5rem;
          min-width: 16rem; }
        .innovations-slider .slider-cell__content {
          max-width: 36rem;
          padding-right: 3rem; }
      .innovations-slider .slider__links {
        -ms-flex-direction: row;
        flex-direction: row; }
        .innovations-slider .slider__links .button.ghost {
          margin: 0 1.5rem 0 0; }
      .innovations-slider .flickity-page-dots {
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding-right: 0;
        position: absolute;
        bottom: -6rem;
        left: 0;
        width: 100%; }
        .innovations-slider .flickity-page-dots:before {
          left: 50%;
          width: 100%; }
        .innovations-slider .flickity-page-dots .dot:last-child {
          margin-right: 0; }
      .innovations-slider .flickity-button.next {
        right: -1.5rem; }
      .innovations-slider .flickity-button.previous {
        left: -1.5rem; } }
  @media screen and (min-width: 62rem) {
    .innovations-slider .slider-cell__image {
      height: 20rem;
      width: 20rem;
      margin-left: 4rem;
      margin-right: 6rem;
      min-width: 20rem; }
    .innovations-slider .flickity-button.next {
      right: 0; }
    .innovations-slider .flickity-button.previous {
      left: 0; } }
  @media screen and (min-width: 80rem) {
    .innovations-slider .slider-cell__image {
      height: 22rem;
      width: 22rem;
      margin-left: 7rem;
      min-width: 22rem; } }

/**
 * Newsroom post feed
 * DBS>Interactive
 */
.partial-blog-feed {
  padding-bottom: 3rem;
  padding-top: 3rem;
  position: relative; }
  @media screen and (min-width: 48rem) {
    .partial-blog-feed {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  @media screen and (max-width: 47.999rem) {
    .partial-blog-feed {
      background: #F2F5FA; } }
  .partial-blog-feed .blog-sidebar {
    display: none; }

.latest-news .h4 {
  position: relative; }
  .latest-news .h4:after {
    border-top: 2px solid #00a78d;
    content: '';
    position: absolute;
    top: .8rem;
    right: 0;
    width: calc(100% - 10rem); }
    @media screen and (min-width: 48rem) {
      .latest-news .h4:after {
        display: none; } }
    @media screen and (min-width: 80rem) {
      .latest-news .h4:after {
        display: block;
        left: 11rem;
        right: auto;
        width: 7.5rem; } }

.latest-news .post {
  background: #ffffff;
  padding: 1.5rem;
  outline: 1px solid #cbd0d6;
  transition: .25s ease-in-out; }
  .latest-news .post__meta {
    margin-bottom: 1.5rem; }
  .latest-news .post__title a {
    font-weight: 700; }
  .latest-news .post__image {
    display: none; }
  .latest-news .post:hover {
    background: #F6FCFC; }
    .latest-news .post:hover .arrow-link.boxed:after {
      background-color: #007966;
      background-image: url("../icons/src/arrow-right-white-2.svg");
      transform: translate(0.25rem, -50%); }

.latest-news .blog-main {
  margin-bottom: 1.5rem; }

@media screen and (min-width: 48rem) {
  .latest-news .post {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%; }
    .latest-news .post__link {
      margin-top: auto; }
  .latest-news .blog-main {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }

@media screen and (min-width: 62rem) {
  .latest-news .post {
    width: 25%; } }

.layout-facilities__heading {
  position: relative; }
  .layout-facilities__heading:after {
    border-top: 2px solid #00A78d;
    content: '';
    position: absolute;
    top: 0.75rem;
    right: 0;
    width: calc(100% - 8.5rem); }

.layout-facilities__left {
  padding-bottom: 3rem;
  padding-top: 3rem; }
  @media screen and (min-width: 48rem) {
    .layout-facilities__left {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }

.layout-facilities__map {
  background: #F6FCFC;
  border-bottom: 1px solid #F2F5FA;
  margin: 0 -1.5rem;
  width: calc(100% + 3rem); }

.layout-facilities .link-list li:last-child {
  margin-bottom: 3rem; }

.layout-facilities .link-list a {
  display: block; }

@media screen and (min-width: 48rem) {
  .layout-facilities {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse; }
    .layout-facilities__map {
      border: none;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 0;
      position: relative;
      width: 60%; }
      .layout-facilities__map:after {
        background: #F6FCFC;
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        width: 50vw;
        z-index: -1; }
    .layout-facilities__left {
      background: #ffffff;
      position: relative;
      width: 40%;
      z-index: 20; }
    .layout-facilities .link-list a {
      font-size: 1.25rem;
      padding-right: 4.5rem; }
      .layout-facilities .link-list a:after {
        right: 1.5rem; } }

@media screen and (min-width: 62rem) {
  .layout-facilities__left {
    width: 24%; }
  .layout-facilities__map {
    width: 76%; } }

.partial-subscribe.layout {
  border-top: 1px solid #cbd0d6;
  margin: 0 auto; }

.subscribe {
  padding: 3rem 0; }
  @media screen and (min-width: 48rem) {
    .subscribe {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: space-evenly;
      justify-content: space-evenly; }
      .subscribe .h4 {
        margin-bottom: 0;
        padding-right: 4.5rem; } }

.innovations-slider + .partial-subscribe {
  margin-top: -1px !important;
  position: relative;
  z-index: 2; }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  height: 2rem;
  position: relative; }
  @media screen and (max-width: 47.999rem) {
    #dbs-chev {
      display: block;
      margin-top: 3rem; } }
  @media screen and (min-width: 48rem) {
    #dbs-chev {
      float: right; } }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.60883em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 17em; }
    @media (min-width: 48rem) {
      #dbs-chev strong {
        font-size: 0.76104em; } }
    @media screen and (min-width: 48rem) {
      #dbs-chev strong {
        width: 17em; } }
  #dbs-chev .text {
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 14em; }

.facilities-map {
  background-color: #F6FCFC;
  position: relative;
  width: 100%; }
  .facilities-map .kao-map {
    padding: 1.5rem 0;
    width: 100%; }
  .facilities-map .map-dot {
    height: 0.5rem;
    width: 0.5rem;
    background: 0;
    border: none;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    transition: .25s ease-in-out;
    transform-origin: center;
    z-index: 1; }
    .facilities-map .map-dot:before, .facilities-map .map-dot:after {
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .25s ease-in-out; }
    .facilities-map .map-dot:before {
      background: #007966;
      z-index: 2; }
    .facilities-map .map-dot:after {
      height: 2.5rem;
      width: 2.5rem;
      background: rgba(0, 167, 141, 0);
      border: 1px solid rgba(255, 255, 255, 0);
      z-index: 3; }
    .facilities-map .map-dot:hover, .facilities-map .map-dot:focus, .facilities-map .map-dot.active {
      z-index: 10; }
      .facilities-map .map-dot:hover:before, .facilities-map .map-dot:focus:before, .facilities-map .map-dot.active:before {
        height: 3.75rem !important;
        width: 3.75rem !important; }
      .facilities-map .map-dot:hover:after, .facilities-map .map-dot:focus:after, .facilities-map .map-dot.active:after {
        background: #00A78d !important;
        border-color: #ffffff !important;
        border-width: 1px !important;
        height: 2.5rem !important;
        width: 2.5rem !important; }
      .facilities-map .map-dot:hover.africa ~ .facility-info.africa, .facilities-map .map-dot:focus.africa ~ .facility-info.africa, .facilities-map .map-dot.active.africa ~ .facility-info.africa {
        opacity: 1;
        transform: translate3d(calc(-100% - 1.5rem), -50%, 0);
        transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s 0s linear;
        visibility: visible;
        z-index: 20; }
      .facilities-map .map-dot:hover.americas ~ .facility-info.americas, .facilities-map .map-dot:focus.americas ~ .facility-info.americas, .facilities-map .map-dot.active.americas ~ .facility-info.americas {
        opacity: 1;
        transform: translate3d(calc(-100% - 1.5rem), -50%, 0);
        transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s 0s linear;
        visibility: visible;
        z-index: 20; }
      .facilities-map .map-dot:hover.asia ~ .facility-info.asia, .facilities-map .map-dot:focus.asia ~ .facility-info.asia, .facilities-map .map-dot.active.asia ~ .facility-info.asia {
        opacity: 1;
        transform: translate3d(calc(-100% - 1.5rem), -50%, 0);
        transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s 0s linear;
        visibility: visible;
        z-index: 20;
        transform: translate3d(100%, -50%, 0); }
      .facilities-map .map-dot:hover.europe ~ .facility-info.europe, .facilities-map .map-dot:focus.europe ~ .facility-info.europe, .facilities-map .map-dot.active.europe ~ .facility-info.europe {
        opacity: 1;
        transform: translate3d(calc(-100% - 1.5rem), -50%, 0);
        transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s 0s linear;
        visibility: visible;
        z-index: 20; }
    .facilities-map .map-dot.medium {
      height: 0.75rem;
      width: 0.75rem; }
      .facilities-map .map-dot.medium:after {
        height: 16px;
        width: 16px;
        border: 0.25rem solid #ffffff; }
      .facilities-map .map-dot.medium:before {
        height: 18px;
        width: 18px; }
    .facilities-map .map-dot.large {
      height: 1rem;
      width: 1rem; }
      .facilities-map .map-dot.large:after {
        height: 22px;
        width: 22px;
        border: 0.25rem solid #ffffff; }
      .facilities-map .map-dot.large:before {
        height: 24px;
        width: 24px; }
    .facilities-map .map-dot:not(.large):hover.americas ~ .map-dot.large.americas, .facilities-map .map-dot:not(.large):focus.americas ~ .map-dot.large.americas {
      z-index: 10; }
      .facilities-map .map-dot:not(.large):hover.americas ~ .map-dot.large.americas:before, .facilities-map .map-dot:not(.large):focus.americas ~ .map-dot.large.americas:before {
        height: 3.75rem !important;
        width: 3.75rem !important; }
      .facilities-map .map-dot:not(.large):hover.americas ~ .map-dot.large.americas:after, .facilities-map .map-dot:not(.large):focus.americas ~ .map-dot.large.americas:after {
        background: #00A78d !important;
        border-color: #ffffff !important;
        border-width: 1px !important;
        height: 2.5rem !important;
        width: 2.5rem !important; }
    .facilities-map .map-dot:not(.large):hover.asia ~ .map-dot.large.asia, .facilities-map .map-dot:not(.large):focus.asia ~ .map-dot.large.asia {
      z-index: 10; }
      .facilities-map .map-dot:not(.large):hover.asia ~ .map-dot.large.asia:before, .facilities-map .map-dot:not(.large):focus.asia ~ .map-dot.large.asia:before {
        height: 3.75rem !important;
        width: 3.75rem !important; }
      .facilities-map .map-dot:not(.large):hover.asia ~ .map-dot.large.asia:after, .facilities-map .map-dot:not(.large):focus.asia ~ .map-dot.large.asia:after {
        background: #00A78d !important;
        border-color: #ffffff !important;
        border-width: 1px !important;
        height: 2.5rem !important;
        width: 2.5rem !important; }
    .facilities-map .map-dot:not(.large):hover.africa ~ .map-dot.large.africa, .facilities-map .map-dot:not(.large):focus.africa ~ .map-dot.large.africa {
      z-index: 10; }
      .facilities-map .map-dot:not(.large):hover.africa ~ .map-dot.large.africa:before, .facilities-map .map-dot:not(.large):focus.africa ~ .map-dot.large.africa:before {
        height: 3.75rem !important;
        width: 3.75rem !important; }
      .facilities-map .map-dot:not(.large):hover.africa ~ .map-dot.large.africa:after, .facilities-map .map-dot:not(.large):focus.africa ~ .map-dot.large.africa:after {
        background: #00A78d !important;
        border-color: #ffffff !important;
        border-width: 1px !important;
        height: 2.5rem !important;
        width: 2.5rem !important; }
    .facilities-map .map-dot:not(.large):hover.europe ~ .map-dot.large.europe, .facilities-map .map-dot:not(.large):focus.europe ~ .map-dot.large.europe {
      z-index: 10; }
      .facilities-map .map-dot:not(.large):hover.europe ~ .map-dot.large.europe:before, .facilities-map .map-dot:not(.large):focus.europe ~ .map-dot.large.europe:before {
        height: 3.75rem !important;
        width: 3.75rem !important; }
      .facilities-map .map-dot:not(.large):hover.europe ~ .map-dot.large.europe:after, .facilities-map .map-dot:not(.large):focus.europe ~ .map-dot.large.europe:after {
        background: #00A78d !important;
        border-color: #ffffff !important;
        border-width: 1px !important;
        height: 2.5rem !important;
        width: 2.5rem !important; }
  .facilities-map .facility-info {
    background-color: rgba(0, 121, 102, 0.95);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100%;
    opacity: 0;
    padding: 1.5rem;
    position: absolute;
    top: 50%;
    left: calc(100% + 3rem);
    transform: translate3d(0, -50%, 0);
    transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s .25s linear;
    visibility: hidden;
    width: 20rem; }
    .facilities-map .facility-info.active {
      opacity: 1;
      transform: translate3d(calc(-100% - 1.5rem), -50%, 0);
      transition: opacity .25s ease-in-out, transform, .25s ease-in-out, visibility 0s 0s linear;
      visibility: visible;
      z-index: 20; }
    .facilities-map .facility-info.asia {
      left: auto;
      right: calc(100% + 1.5rem); }
      .facilities-map .facility-info.asia.active {
        transform: translate3d(100%, -50%, 0); }
    .facilities-map .facility-info__toggle {
      background: url("../icons/src/close-symbol-white.svg") no-repeat center;
      background-size: 2.5rem;
      height: 5rem;
      position: absolute;
      top: 0;
      right: 0;
      width: 5rem; }
    @media screen and (max-width: 47.999rem) {
      .facilities-map .facility-info {
        border: 1px solid #ffffff; } }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
.site-footer {
  overflow: hidden;
  clear: both;
  margin-top: auto; }
  .site-footer__top {
    border-top: 1px solid #cbd0d6; }
    .site-footer__top .contain {
      padding: 3rem 1.5rem;
      text-align: center; }
      .site-footer__top .contain div {
        margin: 1rem auto .5rem; }
  .site-footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .site-footer a {
    text-decoration: none; }
  .site-footer .site-footer__logo svg {
    max-width: 12em;
    margin-bottom: 1.5rem; }
  .site-footer__column {
    padding: 3rem 1.5rem;
    width: 100%; }
  .site-footer__cta .h4 {
    border-left: 2px solid #ffffff;
    padding-left: 1rem; }
  .site-footer__phone {
    border-bottom: 1px solid #cbd0d6; }
    .site-footer__phone .h4 {
      position: relative; }
      .site-footer__phone .h4:after {
        border-top: 2px solid #00A78d;
        content: '';
        position: absolute;
        top: 0.75rem;
        right: 0;
        width: calc(100% - 7rem); }
    .site-footer__phone .h5 {
      line-height: 1.4; }
    .site-footer__phone-row {
      border-bottom: 1px solid #cbd0d6;
      padding: 1.5rem 0; }
      .site-footer__phone-row:last-child {
        border: none;
        padding-bottom: 0; }
      .site-footer__phone-row a {
        font-size: 1.25rem;
        font-weight: normal;
        text-decoration: underline; }
        .site-footer__phone-row a:focus, .site-footer__phone-row a:hover {
          text-decoration: none; }
      .site-footer__phone-row .h5 {
        margin-bottom: .15rem; }
  .site-footer__main > .contain {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .site-footer__nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 3rem 1.5rem;
    width: 50%; }
    .site-footer__nav-left, .site-footer__nav-right {
      height: 100%; }
    .site-footer__nav .footer-menu__item a {
      display: inline-block;
      font-family: "Oswald", "Verdana", "Helvetica", sans-serif;
      font-size: 1.25rem;
      padding: 1.5rem 0;
      position: relative;
      text-transform: uppercase; }
      .site-footer__nav .footer-menu__item a:after {
        content: '';
        display: block;
        border-bottom: 2px solid #5EC3B3;
        transform: scaleX(0) translate(0, 4px);
        transform-origin: 100% 0;
        transition: transform .25s ease-in-out; }
      .site-footer__nav .footer-menu__item a:hover:after, .site-footer__nav .footer-menu__item a:focus:after {
        transform: scaleX(1) translate(0, 4px);
        transform-origin: 0 100%; }
    .site-footer__nav .footer-menu__item:first-child a {
      padding-top: 0; }
    .site-footer__nav .footer-menu__item:last-child a {
      padding-bottom: 0; }
    .site-footer__nav + .site-footer__nav {
      border-left: 1px solid #cbd0d6; }
  .site-footer .social-media {
    height: 4rem; }
    .site-footer .social-media__link {
      display: inline-block;
      margin-right: .5rem; }
      .site-footer .social-media__link:last-child {
        margin: 0; }
    .site-footer .social-media svg circle {
      transition: .2s ease-in-out;
      transform-origin: 2rem; }
    .site-footer .social-media a {
      height: 4rem;
      width: 4rem;
      display: block; }
      .site-footer .social-media a:hover svg circle:first-child, .site-footer .social-media a:focus svg circle:first-child {
        transform: scale(0.85); }
  .site-footer__credits {
    border-top: 1px solid #cbd0d6;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 3rem 1.5rem;
    text-align: center;
    width: 100%; }
    .site-footer__credits .legal-footer {
      display: inline-block;
      margin-top: 1rem; }
      .site-footer__credits .legal-footer ul, .site-footer__credits .legal-footer li {
        display: inline-block; }
      .site-footer__credits .legal-footer .legal-menu__item {
        border-right: 1px solid #818f9c;
        margin-right: .5rem;
        padding-right: .75rem; }
        .site-footer__credits .legal-footer .legal-menu__item:last-child {
          border: none;
          margin: 0;
          padding: 0; }
        .site-footer__credits .legal-footer .legal-menu__item a {
          font-weight: normal;
          text-decoration: underline; }
  @media screen and (min-width: 48rem) {
    .site-footer__top .contain {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center; }
      .site-footer__top .contain .site-footer__logo svg {
        display: block;
        margin-bottom: 0;
        max-width: 16rem; }
      .site-footer__top .contain div {
        margin: 0 1.5rem 0 auto; }
    .site-footer__main {
      border-top: 1px solid #cbd0d6;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .site-footer__cta {
      position: relative; }
      .site-footer__cta:before {
        background: #007966;
        content: '';
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;
        width: 100vw; }
    .site-footer__column {
      padding: 4.5rem 1.5rem;
      width: 50%; }
      .site-footer__column.site-footer__nav {
        border-left: 1px solid #cbd0d6;
        border-top: none; }
    .site-footer__credits {
      padding: 1.5rem;
      text-align: left; }
      .site-footer__credits > .contain {
        width: 100%; } }
  @media screen and (min-width: 62rem) {
    .site-footer__column {
      width: 25%; } }
  @media screen and (min-width: 80rem) {
    .site-footer__column:not(.site-footer__cta) {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

@media screen and (max-width: 47.999rem) {
  ul.mega-menu .mega-menu__column {
    border-bottom: 1px solid #cbd0d6;
    position: relative; }
    ul.mega-menu .mega-menu__column .h5 {
      font-family: "Montserrat", "Verdana", "Helvetica", sans-serif;
      font-weight: 700;
      font-size: 1rem;
      padding: 1.5rem 1.5rem 1.5rem 3rem;
      text-transform: none;
      margin: 0; }
    ul.mega-menu .mega-menu__column ul {
      max-height: 0;
      visibility: hidden; }
    ul.mega-menu .mega-menu__column .submenu-toggle.open + ul {
      max-height: none;
      visibility: visible; }
  ul.mega-menu .main-menu__item {
    border-top: 1px solid #cbd0d6; }
    ul.mega-menu .main-menu__item a {
      padding-left: 4.5rem; } }

@media screen and (min-width: 48rem) {
  ul.mega-menu {
    display: -ms-flexbox;
    display: flex;
    padding: 1.5rem !important; }
    ul.mega-menu .h5 {
      border-bottom: 2px solid #5EC3B3;
      margin-bottom: 1rem;
      padding-bottom: .25rem; }
    ul.mega-menu .mega-menu__column:not(:last-child) {
      padding-right: 1.5rem; }
    ul.mega-menu .main-menu__item {
      min-width: 17rem; }
      ul.mega-menu .main-menu__item a {
        padding-left: 0;
        padding-right: 0; }
        ul.mega-menu .main-menu__item a:after {
          content: '';
          display: block;
          border-bottom: 2px solid #5EC3B3;
          transform: scaleX(0) translate(0, 2px);
          transform-origin: 100% 0;
          transition: transform .3s ease-in-out; }
        ul.mega-menu .main-menu__item a:hover, ul.mega-menu .main-menu__item a:focus {
          background: #ffffff; }
          ul.mega-menu .main-menu__item a:hover:after, ul.mega-menu .main-menu__item a:focus:after {
            transform: scaleX(1) translate(0, 2px);
            transform-origin: 0 100%; }
    ul.mega-menu .submenu-toggle {
      display: none; } }

@media screen and (min-width: 62rem) {
  ul.mega-menu .mega-menu__column:not(:last-child) {
    padding-right: 3rem; } }

/**
 * Global Slider Styles
 * DBS>Interactive
 */
.slider-cell {
  min-height: 100%;
  width: 100%; }

.flickity-viewport:focus,
.flickity-enabled:focus {
  box-shadow: none; }

.slider-cell__wrapper {
  width: 100%; }

.slider-cell__image {
  height: 14rem;
  width: 14rem;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: relative; }
  .slider-cell__image img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 50%; }
    @supports (object-fit: contain) {
      .slider-cell__image img {
        height: 100%;
        object-fit: cover;
        object-position: center; } }

.flickity-button {
  background-color: #ffffff;
  background-image: url("../icons/src/arrow-right-green.svg") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 1rem !important;
  border: 1px solid #007966;
  padding: 0;
  transform-origin: 50% 25%; }
  .flickity-button.previous {
    left: .75rem;
    transform: rotate(180deg); }
  .flickity-button.next {
    right: .75rem; }
  .flickity-button-icon {
    display: none; }

.flickity-page-dots {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative; }
  .flickity-page-dots .dot {
    height: 1rem;
    width: 1rem;
    background: white;
    border: 1px solid #007966;
    opacity: 1; }
    .flickity-page-dots .dot.is-selected {
      background: #007966; }

.flickity-page-dots {
  margin-top: 1.5rem;
  padding: 1.5rem;
  position: relative;
  bottom: 0; }
  .flickity-page-dots:before {
    background: #F2F5FA;
    content: '';
    height: 100%;
    position: absolute;
    left: 3rem;
    width: 100%;
    z-index: -1; }

.flickity-viewport {
  overflow: visible; }

@media screen and (min-width: 80rem) {
  .flickity-button {
    height: 4rem;
    width: 4rem;
    background-size: 1.25rem !important;
    border-width: 2px; } }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
