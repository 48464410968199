.innovations-slider {
	border-top: 1px solid $grayLightest;
	border-bottom: 1px solid $grayLightest;
	text-align: center;

	// Element that flickity instantiates on
	.slider {
		padding: 0 $spacing;

		&-cell {
			opacity: 0;
			transition: .25s ease-in-out;
		}

		&-cell__wrapper {

			&.is-selected { .slider-cell { opacity: 1; } }
		}
	}

	.slider__links {
		display: flex;
			flex-direction: column;

		.button.ghost {margin-bottom: $spacing;}
	}

	@include media($screen-sm-max, 'max') {
		padding-left: 0 !important;
		padding-right: 0 !important;

		&:before {content: none;}
		
		.flickity-button {
			top: 7rem; // Half of image width set above
		}

		.flickity-viewport {overflow: hidden;}

		&__header {
			@include vertical-padding;
			background-color: $greenLighter;
			padding-bottom: 12rem;
	
			.h3 {margin-bottom: 0;}
		}

		.slider {
			margin-top: -7rem; // Half of image width set below

			&-cell {
				&__image {
					margin: 0 auto #{$spacing * 3};
				}
			}
		}
	}


	@include media($screen-sm) {
		padding-bottom: 6rem;
		position: relative;
		text-align: left;
		
		&__header {
			padding-bottom: $spacing;
			padding-left: 0;
			padding-top: 4.5rem;
		}

		.slider {

			&-cell {
				display: flex;
					align-items: center;
					margin-left: auto;
					// width: 80%;

					&__image {
						@include size(16rem);
						margin-right: $spacing*3;
						min-width: 16rem;
					}

					&__content {
						max-width: 36rem;
						padding-right: $spacing * 2;
					}
			}
		}

		.slider__links {
			flex-direction: row;

			.button.ghost {
				margin: 0 $spacing 0 0;
			}
		}

		.flickity-page-dots {
			justify-content: flex-end;
			padding-right: 0;
			position: absolute;
				bottom: -6rem;
				left: 0;
			width: 100%;

			&:before {
				left: 50%;
				width: 100%;
			}

			.dot:last-child {margin-right: 0;}
		}

		.flickity-button.next {right: -$spacing;}
		.flickity-button.previous {left: -$spacing;}
	}

	@include media($screen-md) {

		.slider-cell {
			&__image {
				@include size(20rem);
				margin-left: 4rem;
				margin-right: $spacing*4;
				min-width: 20rem;
			}
		}

		.flickity-button.next {right: 0;}
		.flickity-button.previous {left: 0;}
	}

	@include media($screen-lg) {
		.slider-cell {
			&__image {
				@include size(22rem);
				margin-left: 7rem;
				min-width: 22rem;
			}
		}
	}
}