/**
 * Minimal-Glyph Fonts For English Site
 */

 @font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Black.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Bold.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraBold.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Light.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Medium.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraLight.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Montserrat';
//     src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-SemiBold.woff2') format('woff2'),
//         url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-SemiBold.woff') format('woff');
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Regular.woff2') format('woff2'),
         url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Thin.woff2') format('woff2'),
        url('/wp-content/themes/kao-print/library/fonts/montserrat/subset-Montserrat-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Oswald';
    src: url('/wp-content/themes/kao-print/library/fonts/oswald/subset-Oswald-Bold.woff2') format('woff2'),
         url('/wp-content/themes/kao-print/library/fonts/oswald/subset-Oswald-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


/**
 * Primary Font Family
 */
$font-montserrat: "Montserrat", "Verdana", "Helvetica", sans-serif;
$font-oswald: "Oswald", "Verdana", "Helvetica", sans-serif;