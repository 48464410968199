/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

.site-footer {
	overflow: hidden;
	clear: both;
	margin-top: auto; // Sticky footer

	&__top {
		border-top: 1px solid $grayLighter;

		.contain {
			padding: $spacing*2 $spacing;
			text-align: center;

			div {
				margin: 1rem auto .5rem;
			}
		}
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {text-decoration: none;}

	.site-footer__logo svg {
		max-width: 12em;
		margin-bottom: $spacing;
	}

	&__column {
		padding: #{$spacing * 2} $spacing;
		width: 100%;
	}

	&__cta {
		.h4 {
			border-left: 2px solid $white;
			padding-left: 1rem;
		}
	}

	&__phone {
		border-bottom: 1px solid $grayLighter;

		.h4 {
			position: relative;

			&:after {
				border-top: 2px solid $greenLighter;
				content: '';
				position: absolute;
					top: 0.75rem;
					right: 0;
				width: calc(100% - 7rem);
			}
		}

		.h5 {line-height: 1.4;}

		&-row {
			border-bottom: 1px solid $grayLighter;
			padding: $spacing 0;

			&:last-child {
				border: none;
				padding-bottom: 0;
			}

			a {
				font-size: 1.25rem;
				font-weight: normal;
				text-decoration: underline;

				&:focus,
				&:hover {text-decoration: none;}
			}

			.h5 {margin-bottom: .15rem;}
		}
	}

	&__main {
		> .contain {
			display: flex;
				flex-wrap: wrap;
		}
	}

	&__nav {
		display: flex;
			align-items: flex-start;
		padding: #{$spacing * 2} $spacing;
		width: 50%;

		&-left,
		&-right {
			height: 100%;
		}

		.footer-menu__item {
			a {
				display: inline-block;
				font-family: $font-oswald;
				font-size: 1.25rem;
				padding: $spacing 0;
				position: relative;
				text-transform: uppercase;

				&:after {
					content: '';
					display: block;
					border-bottom: 2px solid $greenLightest;
					transform: scaleX(0) translate(0, 4px);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					&:after {
						transform: scaleX(1) translate(0, 4px);
						transform-origin: 0 100%;
					}
				}
			}

			&:first-child a {padding-top: 0;}
			&:last-child a {padding-bottom: 0;}
		}

		+ .site-footer__nav {border-left: 1px solid $grayLighter;}
	}

	.social-media {
		height: 4rem;

		&__link {
			display: inline-block;
			margin-right: .5rem;

			&:last-child {margin: 0;}
		}

		svg circle {
			transition: .2s ease-in-out;
			transform-origin: 2rem;
		}

		a {
			@include size(4rem);
			display: block;

			&:hover,
			&:focus {
				svg {
					circle:first-child {transform: scale(.85);}
				}
			}
		}
	}

	&__credits {
		border-top: 1px solid $grayLighter;
		display: flex;
			align-items: flex-end;
			justify-content: space-between;
		padding: #{$spacing*2} $spacing;
		text-align: center;
		width: 100%;

		.legal-footer {
			display: inline-block;
			margin-top: 1rem;

			ul, li {display: inline-block;}

			.legal-menu__item {
				border-right: 1px solid $gray;
				margin-right: .5rem;
				padding-right: .75rem;

				&:last-child {
					border: none;
					margin: 0;
					padding: 0;
				}

				a {
					font-weight: normal;
					text-decoration: underline;
				}
			}
		}
	}

	@include media($screen-sm) {
		&__top {
			.contain {
				display: flex;
					align-items: center;

				.site-footer__logo svg {
					display: block;
					margin-bottom: 0;
					max-width: 16rem;
				}

				div {margin: 0 $spacing 0 auto;}
			}
		}
		&__main {
			border-top: 1px solid $grayLighter;
			flex-wrap: nowrap;
		}

		&__cta {
			position: relative;

			&:before { // Extends green background past container
				background: $green;
				content: '';
				height: 100%;
				position: absolute;
					right: 100%;
					top: 0;
				width: 100vw;
			}
		}

		&__column {
			padding: #{$spacing * 3} $spacing;
			width: 50%;

			&.site-footer__nav {
				border-left: 1px solid $grayLighter;
				border-top: none;
			}
		}

		&__credits {
			padding: $spacing;
			text-align: left;

			& > .contain {width: 100%;}
		}
	}

	@include media($screen-md) {
		&__column {
			width: 25%;
		}
	}

	@include media($screen-lg) {
		&__column:not(.site-footer__cta) {
			padding-left: 2.5rem;
			padding-right: 2.5rem;
		}
	}
}
