.layout-sustainability {
	border-bottom: 1px solid $grayLightest;
	border-top: 1px solid $grayLightest;
	overflow: hidden;

	.eyebrow { color: $green; }

	&__container {
		@include vertical-padding;
		display: flex;
			flex-direction: column-reverse;
		position: relative;
	}

	&__left {
		text-align: center;
	}

	&__right {
		margin-bottom: $spacing * 3;
		text-align: center;
		position: relative;

		h2 {margin-bottom: $spacing*2;}
	}

	&__img {
		margin: 0 auto;
		width: calc(100% - 6rem);
	}

	&__accent {
		position: absolute;
			bottom: 0;
			left: 0;
		width: 7rem;
	}

	@include media($screen-sm-max, 'max') {
		&__img::after {
			background: $mint;
			content: '';
			height: 150%;
			position: absolute;
				left: 50%;
				bottom: 50%;
			transform: translate(-50%,0);
			width: 100vw;
			z-index: -1;
		}
	}

	@include media($screen-sm) {
		overflow-y: hidden;

		&__container {
			align-items: center;
			flex-direction: row;
		}

		&__left {
			text-align: left;
			width: 55%;
		}

		&__right {
			padding-left: 4rem;
			text-align: right;
			width: 45%;
		}

		&__accent {
			bottom: -3rem;
			left: 1.5rem;
		}

		.header-lines__line:last-child {visibility: hidden;}
	}

	@include media($screen-md) {
		&__container {
			&:before {
				background: repeating-linear-gradient($greenLightest, $greenLightest 1px, white 0px 3px, white 3px);
				content: '';
				height: 30%;
				position: absolute;
					left: calc(100% - 1.5rem);
					bottom: 0;
				width: 50vw;
				z-index: 0;
			}
		}

		&__right {
			&:after {
				background: $mint;
				content: '';
				height: calc(100% + 13.5rem);
				position: absolute;
					top: -4.5rem;
					left: calc(50% + 1.5rem);
				width: 50vw;
				z-index: -1;
			}
		}

		&__img {
			width: calc(100% - 4rem);
		}

		&__accent {
			bottom: 0;
			left: 0;
			width: 10rem;
		}
	}
}

.innovations-slider + .layout-sustainability {
	margin-top: -1px !important; // Collapse borders
}