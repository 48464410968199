.layout-facilities {

	&__heading {
		position: relative;

		&:after {
			border-top: 2px solid $greenLighter;
			content: '';
			position: absolute;
				top: 0.75rem;
				right: 0;
			width: calc(100% - 8.5rem);
		}
	}

	&__left {
		@include vertical-padding;
	}

	&__map {
		background: $mint;
		border-bottom: 1px solid $grayLightest;
		margin: 0 -#{$spacing};
		width: calc(100% + #{$spacing * 2});
	}

	.link-list {
		li:last-child {
			margin-bottom: $spacing*2;
		}

		a {display: block;}
	}

	@include media($screen-sm) {
		display: flex;
			flex-direction: row-reverse;

		&__map {
			border: none;
			display: flex;
				flex-direction: column;
				justify-content: center;
			margin: 0;
			position: relative;
			width: 60%;

			&:after {
				background: $mint;
				content: '';
				height: 100%;
				position: absolute;
					top: 0;
					left: 100%;
				width: 50vw;
				z-index: -1;
			}
		}

		&__left {
			background: $white;
			position: relative;
			width: 40%;
			z-index: 20;
		}

		.link-list {
			a {
				font-size: 1.25rem;
				padding-right: 4.5rem;

				&:after {
					right: $spacing;
				}
			}
		}
	}

	@include media($screen-md) {
		&__left {width: 24%;} // These should really be 25/75. Not sure why there's an alignment issue
		&__map {width: 76%;}
	}
}